/** @jsx jsx */
import Image from 'components/image';
import { graphql, useStaticQuery } from 'gatsby';
import { Col } from 'react-bootstrap';
import { Box, Container, Heading, Text, jsx } from 'theme-ui';


const SalesprophetDetail = () => {
    const data = useStaticQuery(graphql`
    query {
      ImageSalesprophet: file(relativePath: { eq: "Salesprophet.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, PNG])
        }
      }
      ImageSalesprophetDesign: file(relativePath: {eq: "salesprophet-features.png"}) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, PNG], quality: 100)
        }
      }
    }
  `);
    return (
        <Box as="section" id="Blog_Details" sx={styles.section}>
            <Container>
                <Col xl="9" lg="9" md="10" className='mx-auto'>
                    <Image
                        src={data.ImageSalesprophet.childImageSharp.gatsbyImageData}
                        loading="lazy"
                        alt="cbc"
                        className="rounded"
                    />
                </Col>
                <Col xl="7" lg="7" md="10" className="mx-auto mb-5">
                    <Heading sx={styles.title}>
                        About SalesProphet
                    </Heading>
                    <Text as="p" sx={styles.summary}>
                        SalesProphet is the ultimate tool for agencies and B2B professionals, offering a streamlined approach to finding and engaging with leads. Built by an agency, for agencies, it provides access to over 86 million contacts, allowing you to add them to a multi-channel sequence effortlessly.
                    </Text>
                    <Heading sx={styles.title}>
                        The Challenge
                    </Heading>
                    <Text as="p" sx={styles.summary} className='mb-4'>
                        Finding and converting leads can be a daunting task. SalesProphet addresses this challenge by providing a user-friendly platform that simplifies the lead generation process, helping you focus on growing your customer base.
                    </Text>
                    <Heading sx={styles.title}>
                        The Solution
                    </Heading>
                    <Text as="p" sx={styles.summary} className='mb-4'>
                        With SalesProphet, you can search our extensive database of contacts and add them to your multi-channel sequence with ease. This allows you to reach out to potential customers across various channels, increasing your chances of conversion.
                    </Text>
                    <Heading sx={styles.title}>
                        Get Started Today
                    </Heading>
                    <Text as="p" sx={styles.summary} className='mb-4'>
                        Elevate your lead generation efforts with SalesProphet. Sign up today and start connecting with your next customer!
                    </Text>
                </Col>
                <Col xl="7" lg="7" md="10" className="mx-auto pb-5">
                    <Heading sx={styles.title}>
                        Streamlined Design Process
                    </Heading>
                    <Text as="p" sx={styles.summary} className='mb-4'>
                        The design of SalesProphet was driven by our commitment to simplicity and user-friendliness. We began by creating wireframes to visualize the app's interface, ensuring that every element serves a clear purpose and enhances the user experience.
                    </Text>
                    <Text as="p" sx={styles.summary} className='mb-4'>
                        Our focus was on creating a platform that simplifies lead management for agencies and B2B professionals. We aimed for a seamless experience that allows users to effortlessly navigate the app, from adding contacts to creating multi-channel sequences.
                    </Text>
                </Col>

                <Col xl="9" lg="9" md="10" className='mx-auto'>
                    <Image
                        src={data.ImageSalesprophetDesign.childImageSharp.gatsbyImageData}
                        loading="lazy"
                        alt="Webhelp formation"
                        className=""
                    />
                </Col>
                <Col xl="7" lg="7" md="10" className="mx-auto py-5">
                    <Heading sx={styles.title}>
                        Key Features
                    </Heading>
                    <Text as="p" sx={styles.summary} className='mb-4'>
                        SalesProphet offers a range of powerful features designed to streamline your sales process and boost your productivity. Here are some of the key features:
                    </Text>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item bg-transparent">
                            Searching for Contacts - Find and add ideal prospects to lists, and verify contacts before adding them to campaigns.
                        </li>
                        <li class="list-group-item bg-transparent">
                            Email Sequences - Create text-based sales sequences and set up A/B/C test splits for better open and reply rates.
                        </li>
                        <li class="list-group-item bg-transparent">
                            Mass Email & Newsletters - Use a drag-and-drop email builder for branded HTML emails, perfect for newsletters and lead nurturing.
                        </li>
                        <li class="list-group-item bg-transparent">
                            Account-based Email - Send one email to all influencers in key accounts involved in the buying decision.
                        </li>
                        <li class="list-group-item bg-transparent">
                            Adding Email Accounts - Add email accounts with one-click integration.
                        </li>
                        <li class="list-group-item bg-transparent">
                            Adding SMTP Accounts - Integrate with Mailgun, SparkPost, and SendGrid for more email volume or subdomain sending.
                        </li>
                        <li class="list-group-item bg-transparent">
                            Creating Forms - Use a drag-and-drop form builder to collect leads on your website.
                        </li>
                        <li class="list-group-item bg-transparent">
                            Opportunity Management - Manage opportunities and tasks with an easy-to-use CRM.
                        </li>
                        <li class="list-group-item bg-transparent">
                            Dashboards & Reporting - Track campaign results, meetings booked, opportunities generated, and sales activities.
                        </li>
                        <li class="list-group-item bg-transparent">
                            Help Desk - Access 24/7 help desk filled with videos and content on using Sales Prophet.
                        </li>
                    </ul>
                </Col>

            </Container>
        </Box >
    );
};

export default SalesprophetDetail;

const styles = {
    section: {
        backgroundColor: '#f3f6fe',
        pt: [8, null, 12, 6, null, 10],
        pb: [null, null, null, 8, 0],
    },
    grid: {
        gap: [null, null, null, null, 12],
        display: ['flex', null, null, 'grid'],
        flexDirection: ['column-reverse', null, null, 'unset'],
        alignItems: 'center',
        gridTemplateColumns: ['1fr', null, null, null, '470px 1fr', '1fr 549px'],
    },
    illustration: {
        textAlign: 'center',
        position: 'relative',
        mt: [2, null, null, 0, 4, 0],
        img: {
            maxWidth: ['100%', null, null, null, null, ''],
        },
    },
    content: {
        marginTop: [null, null, null, null, null, -16],
        maxWidth: [null, null, null, 420, 560, 'none'],
        margin: [null, null, null, '0 auto', 'unset'],
        textAlign: ['center', null, null, null, 'left'],
        mb: 4
    },
    titlePhase: {
        color: '#ec1c23',
        fontFamily: 'headingAlt',
        fontSize: [4, null, null, 8, null, null, 14],
        fontWeight: 500,
        lineHeight: [1.33, null, 1.4, 1.2],
        letterSpacing: ['-0.5px', null, null, '-1px'],
        mb: 3,
        mt: 6,
        span: {
            backgroundSize: 'cover',
            px: 2,
        },
    },
    title: {
        color: '#09386b',
        fontFamily: 'headingAlt',
        fontSize: [4, null, null, 8, null, null, 14],
        fontWeight: 500,
        lineHeight: [1.33, null, 1.4, 1.2],
        letterSpacing: ['-0.5px', null, null, '-1px'],
        mb: 3,
        mt: 6,
        span: {
            backgroundSize: 'cover',
            px: 2,
        },
    },
    summary: {
        color: 'textSecondary',
        fontFamily: 'headingAlt',
        fontSize: ['15px', null, null, 2, '15px', 4],
        lineHeight: [1.86, null, null, null, 1.86, 1.5],
        m: [null, null, null, '20px auto 0', '15px 0 0', null, 'unset'],
    },
    list: {
        gap: '0 18px',
        fontFamily: 'headingAlt',
        gridTemplateColumns: ['repeat(2, 142px)', null, null, 'repeat(2, 200px)'],
        justifyContent: [null, null, null, 'center', 'unset'],
        listStyle: 'none',
        display: 'block',
        mt: [4, null, null, 5, 4, 5],
        p: 0,
        li: {
            fontSize: [0, 1, null, 2, '15px', 2],
            fontWeight: 600,
            alignItems: 'center',
            color: 'textSecondary',
            fontFamily: 'headingAlt',
            display: 'flex',
            lineHeight: [2.81, null, null, null, 2.2, 2.81],
        },
    },
};
